/* eslint-disable no-bitwise */
import isPropValid from '@emotion/is-prop-valid';

import { useBreakpointUp } from './theme/hooks';

export const pxToRem = (px: number) => `${px / 16}rem`;

export const filterInvalidPropOptions = {
  shouldForwardProp: (prop: PropertyKey) => isPropValid(prop),
};

export const makeHeightBreakpoint = (heightPx: number) =>
  `@media only screen and (min-height: ${heightPx}px)`;

export const makeWidthBreakpoint = (widthPx: number) =>
  `@media only screen and (min-width: ${widthPx}px)`;

export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function rgbToHsv(rgb: [number, number, number]) {
  let [r, g, b] = rgb;

  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);

  let h = 0;
  const v = max;

  const d = max - min;
  const s = max === 0 ? 0 : d / max;

  if (max === min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        h = 0;
    }

    h /= 6;
  }

  return { h, s, v };
}

export function hexToHsv(hex: string) {
  const rgb = hexToRgb(hex);
  if (!rgb) return null;
  return rgbToHsv([rgb.r, rgb.g, rgb.b]);
}

interface UseSizeByBreakpointArgs {
  base: number;
  sm?: number;
  md?: number;
  lg?: number;
}

export const useSizeByBreakpoint = ({
  base,
  sm,
  md,
  lg,
}: UseSizeByBreakpointArgs) => {
  const smUp = useBreakpointUp('sm');
  const mdUp = useBreakpointUp('md');
  const lgUp = useBreakpointUp('lg');

  let size = base;

  if (smUp && sm) {
    size = sm;
  }
  if (mdUp && md) {
    size = md;
  }
  if (lgUp && lg) {
    size = lg;
  }

  return size;
};

// https://gist.github.com/avisek/eadfbe7a7a169b1001a2d3affc21052e
export function rgbToHsl(rgb: { r: number; g: number; b: number }) {
  const r = rgb.r / 255;
  const g = rgb.g / 255;
  const b = rgb.b / 255;

  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h: number = 0;
  let s: number = 0;
  let l = (max + min) / 2;

  if (max === min) {
    // eslint-disable-next-line no-multi-assign
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }

    h /= 6;
  }

  h *= 360;
  s *= 100;
  l *= 100;

  return { h, s, l };
}
