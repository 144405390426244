/* eslint-disable camelcase */
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { TEMP_DarkTheme } from '~/constants/theme';
import { NotificationContextProvider } from '~/libs/context/NotificationContext';

const queryClient = new QueryClient();

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  return (
    <SessionProvider session={session}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={TEMP_DarkTheme}>
          <CssBaseline />
          <NotificationContextProvider>
            <main>
              <Component {...pageProps} />
            </main>
          </NotificationContextProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </SessionProvider>
  );
}

export default MyApp;
